import React, { ReactElement } from "react"

interface Props {
  size?: "sm" | "md" | "lg"
  children?: any
  onClick?: any
  disabled?: boolean
}

function Button({
  size = "sm",
  children,
  onClick,
  disabled,
}: Props): ReactElement {
  const sizes = {
    sm: "p-2",
    md: "py-2 px-4",
    lg: "py-4 px-8",
  }

  return (
    <>
      <button
        disabled={disabled}
        onClick={onClick}
        className={`w-full py-3 active:bg-blue-600 hover:bg-blue-400 bg-blue-500 ${sizes[size]} rounded outline-none text-white`}
      >
        {children}
      </button>
    </>
  )
}

export default Button
