import Auth from "@utils/Auth"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { ReactElement } from "react"

interface INavBarProps {
  currentLocation?: any
  onCurrentLocationClick?: any
  jwt?: string
}

function NavBar({
  currentLocation,
  onCurrentLocationClick,
  jwt,
}: INavBarProps): ReactElement {
  const [visible, setVisible] = React.useState(false)
  const data = useStaticQuery(graphql`
    query NavBarQuery {
      site {
        siteMetadata {
          title
          nav {
            logo {
              title
            }
            menu {
              items {
                title
                href
              }
            }
          }
        }
      }
    }
  `)

  const onMenuIconClick = () => {
    setVisible(!visible)
  }

  const onLoginClick = () => {
    Auth.showLogin()
    setVisible(false)
  }

  const onLinkClick = () => {
    setVisible(false)
  }

  const { nav, title } = data.site.siteMetadata
  const { logo, menu } = nav || {}

  return (
    <>
      <div className="w-full">
        <div className="flex items-center w-full">
          <div className="text-white text-lg md:text-3xl flex items-center">
            <Link className="text-white hover:text-gray-200  flex items-center" to="/">
              <img className="w-16 mr-4" src={require("@images/logo.svg")} />
              <span className="mt-1">{title}</span>
            </Link>
          </div>

          <div className=" hidden md:flex justify-end flex-1">
            {menu?.items && menu?.items.length ? (
              <>
                <ul className="flex items-center list-none">
                  {menu?.items.map((item: any) => (
                    <>
                      <li className="my-2 mx-4">
                        <Link
                          className="text-gray-100 hover:text-gray-200"
                          to={item.href}
                        >
                          {item.title}
                        </Link>
                      </li>
                    </>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
          </div>
          <div className=" ml-4 hidden md:flex justify-end mr-4 bg-blue-600 rounded-full py-2 px-4 text-white">
            <div
              className="flex items-center cursor-pointer mr-2"
              onClick={onCurrentLocationClick}
            >
              <span className="md:hidden">Location</span>
              <span className="hidden md:block mr-2">
                <img
                  src={require("@images/location_icon.svg")}
                  className="w-4"
                />
              </span>{" "}
              <span className="">
                {currentLocation ? currentLocation.name : "Not Selected"}
              </span>
            </div>
            {jwt && jwt.length ? (
              ""
            ) : (
              <div className="flex">
                <a
                  onClick={onLoginClick}
                  className="flex items-center text-gray-100 hover:text-gray-200 ml-2"
                  href="#"
                >
                  <img
                    className="w-4 mr-2"
                    src={require("@images/lock_icon.svg")}
                  />
                  <span>Login</span>
                </a>
              </div>
            )}
          </div>
          <div className="flex md:hidden flex-1 justify-end">
            <img
              onClick={onMenuIconClick}
              className="w-8 cursor-pointer"
              src={require("@images/menu_icon.svg")}
            />
          </div>
        </div>
        {visible ? (
          <div className="flex flex-col md:hidden bg-gray-100 rounded-lg mt-8 shadow-xl overflow-hidden">
            {menu?.items && menu?.items.length ? (
              <>
                <ul className="flex flex-col list-none w-full cursor-pointer">
                  {menu?.items.map((item: any) => (
                    <>
                      <Link
                        className="text-gray-800 hover:text-gray-700"
                        to={item.href}
                        onClick={onLinkClick}
                      >
                        <li className="px-4 py-4 hover:bg-gray-300 w-full">
                          {item.title}
                        </li>
                      </Link>
                    </>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
            <hr />
            <div className=" m-4  text-black justify-end mr-4">
              <div
                className="inline-block cursor-pointer mb-4"
                onClick={() => {
                  onLinkClick()
                  onCurrentLocationClick()
                }}
              >
                Location :{" "}
                <span className="text-gray-500">
                  {currentLocation ? currentLocation.name : "Not Selected"}
                </span>
              </div>
              {jwt && jwt.length ? (
                ""
              ) : (
                <div className="flex">
                  <a
                    onClick={onLoginClick}
                    className="flex items-center text-gray-500 hover:text-gray-400"
                    href="#"
                  >
                    <span>Login</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  )
}

export default NavBar
