import React, { ReactElement } from "react"
import { connect } from "redux-zero/react"
import { useStaticQuery, graphql, Link } from "gatsby"
interface Props {}

function Footer({}: Props): ReactElement {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          description
          footerMenu {
            items {
              title
              items {
                title
                href
              }
            }
          }
        }
      }
    }
  `)
  const { footerMenu, title, description } = data.site.siteMetadata
  const { items } = footerMenu || {}
  return (
    <>
      <div className="flex flex-col justify-center items-center bg-gray-800 text-white w-full p-8">
        <div className="max-w-1366 mx-auto w-full">
          <div className="flex flex-wrap w-full">
            <div className=" text-center md:text-left w-full md:w-1/3">
              <h3 className="text-2xl">{title}</h3>
              <h5>{description}</h5>
            </div>
            <div className="flex flex-wrap text-center md:text-left w-full md:w-2/3 ">
              {items && items.length ? (
                <>
                  {items.map((item: any) => (
                    <>
                      <div className="my-2 w-full md:w-1/3">
                        <div className="mb-2 font-bold text-lg">
                          {item.title}
                        </div>
                        <div className="flex flex-col ">
                          {item.items && item.items.length ? (
                            <>
                              {item.items.map((iitem: any) => (
                                <>
                                  <div className="flex-1 text-sm my-2 ">
                                    <Link
                                      className="text-gray-500 hover:text-gray-100"
                                      to={iitem.href}
                                    >
                                      {iitem.title}
                                    </Link>
                                  </div>
                                </>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapToProps = (state: any) => ({})

export default connect(mapToProps)(Footer)
