import Axios from "axios"

const HOSTNAME = process.env.BACKEND_API_HOSTNAME || "localhost"
const PORT = process.env.BACKEND_API_PORT
const PROTOCOL = process.env.BACKEND_API_PROTOCOL || "http"

const ENDPOINT = PROTOCOL + "://" + HOSTNAME + (PORT ? ":" + PORT : "") + "/"

export default {
  ENDPOINT,
  put: async (url: string, data: any) => {
    return await Axios.put(ENDPOINT + url, data)
  },
  delete: async (url: string) => {
    return await Axios.delete(ENDPOINT + url)
  },
  post: async (url: string, data: any) => {
    return await Axios.post(ENDPOINT + url, data)
  },
  get: async (url: string) => {
    return await Axios.get(ENDPOINT + url)
  },
}
