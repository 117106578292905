import React, { ReactElement } from "react"

export interface ISelectListItem {
  name?: string
  title?: string
  value?: string
  icon?: any
}

export interface ISelectProps {
  size?: "sm" | "md" | "lg"
  list?: ISelectListItem[]
  onChange?: any
  label?: string
  value?: any
  disabled?: boolean
}

function Select({
  size = "sm",
  list,
  onChange,
  label,
  value,
  disabled,
}: ISelectProps): ReactElement {
  const sizes = {
    sm: "p-2",
    md: "py-2 px-4",
    lg: "py-4 px-8",
  }

  React.useEffect(() => {
    if (list && list.length) {
      onChange(list[0].value)
    }
  }, [])

  const _onChange = (e: any) => {
    const v = e.target.value
    if (onChange) {
      onChange(v)
    }
  }

  return (
    <>
      <select
        disabled={disabled}
        className={`bg-gray-100 w-full ${sizes[size]} h-full rounded text-gray-600 outline-none border border-gray-400`}
        onChange={_onChange}
        placeholder={label}
        title={label}
        value={value}
      >
        <option disabled>{label}</option>
        {list && list.length ? (
          <>
            {list.map((item: ISelectListItem, index: number) => (
              <option key={index} value={item.value}>
                {item.title || item.name}
              </option>
            ))}
          </>
        ) : (
          ""
        )}
      </select>
    </>
  )
}

export default Select
